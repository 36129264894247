import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Button,
  Toolbar,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ArrowBack } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import { getManifest, deleteManifest } from '@/actions/manifest';
import {
  getManifestChecks,
  deleteManifestCheck,
} from '@/actions/manifestCheck';
import ManifestDeleteDialog from '../ManifestDeleteDialog';
import toNiceTimestamp from '@/util/toNiceTimestamp';
import AdminManifestCheckDrawer from './AdminManifestCheckDrawer';
import { useDocTypes } from '../../../../providers/DocTypesContext';

function AdminManifest() {
  const user = useUser();
  const configuration = useConfiguration();
  const navigate = useNavigate();
  const doc_types = useDocTypes();
  const { manifest_id } = useParams();
  const [manifest, setManifest] = useState(null);
  const [manifest_checks, setManifestChecks] = useState(null);
  const [loading_manifest, setLoadingManifest] = useState(true);
  const [loading_checks, setLoadingChecks] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [revision, setRevision] = useState(0);

  useEffect(() => {
    if (user?.entity_id && !manifest) {
      getManifest(configuration?.api, manifest_id)
        .then((data) => {
          setManifest(data);
          setLoadingManifest(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingManifest(false);
        });
    }
  }, [
    configuration?.api,
    user?.entity_id,
    manifest,
    manifest_id,
    setManifest,
    setLoadingManifest,
  ]);

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [selected]);

  useEffect(() => {
    if (user?.entity_id && !manifest_checks) {
      getManifestChecks(configuration.api, manifest_id)
        .then((data) => {
          data.sort((a, b) => {
            if (a.section === b.section) {
              return (a.name > b.name) - (a.name < b.name);
            } else {
              return (a.section > b.section) - (a.section < b.section);
            }
          });
          setManifestChecks(data);
          setLoadingChecks(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingChecks(false);
        });
    }
  }, [
    user?.entity_id,
    manifest_checks,
    setManifestChecks,
    configuration.api,
    manifest_id,
    setLoadingChecks,
  ]);

  const handleRowSelection = useCallback(
    (value) => {
      setRowSelectionModel(value);
      if (value?.length === 1) {
        const check = manifest_checks.find(
          (check) => check.entity_id === value[0]
        );
        setSelected(check);
      } else {
        setSelected(null);
      }
    },
    [setRowSelectionModel, manifest_checks, setSelected]
  );

  const handleManifestCheckUpdate = async (manifest_check) => {
    setManifestChecks((prevState) => {
      setRevision((prevState) => prevState + 1);
      return [
        ...prevState.filter(
          (check) => check.entity_id !== manifest_check.entity_id
        ),
        manifest_check,
      ].sort((a, b) => {
        if (a.section === b.section) {
          return (a.name > b.name) - (a.name < b.name);
        } else {
          return (a.section > b.section) - (a.section < b.section);
        }
      });
    });
  };

  const handleDeleteManifestCheck = useCallback(
    async (check_id) => {
      await deleteManifestCheck(configuration.api, manifest_id, check_id);
      setManifestChecks((prevState) => {
        return prevState.filter((check) => check.entity_id !== check_id);
      });
    },
    [configuration.api, manifest_id, setManifestChecks]
  );

  const deleteSelectedManifestChecks = useCallback(
    async (selected) => {
      await Promise.all(
        selected.map(async (check) => {
          await handleDeleteManifestCheck(check);
        })
      );
    },
    [handleDeleteManifestCheck]
  );

  const handleDeleteManifest = async (manifest_id) => {
    await deleteManifest(configuration.api, manifest_id);
    const doc_type = doc_types.find(
      (doc_type) => doc_type.entity_id === manifest.document_type_id
    );
    navigate({
      pathname: '/admin/manifests',
      search: `?type=${doc_type.code}`,
    });
  };

  const renderName = useCallback((params) => {
    return (
      // <Link component={RouterLink} to={`/admin/manifests/${params.id}`}>
      <Typography>{params.row.name}</Typography>
      // </Link>
    );
  }, []);

  const convertDate = useCallback(({ value }) => {
    if (!value) {
      return value;
    }
    return new Date(value.replace(' ', 'T') + 'Z');
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'section',
        headerName: 'Section',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
        renderCell: renderName,
      },
      {
        field: 'critical',
        headerName: 'Critical',
        flex: 0.25,
        type: 'boolean',
      },
      {
        field: 'prompt',
        headerName: 'Prompt',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
      {
        field: 'updated_at',
        headerName: 'Last Changed',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
    ],
    [renderName, convertDate]
  );

  const ManifestCheckGridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <Button
          sx={{ padding: '3px 9px' }}
          variant='outlined'
          onClick={() => {
            setDrawerOpen(true);
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <AddCircleOutlineOutlinedIcon />
            <Typography variant='button' display='block' gutterBottom>
              Add
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{ padding: '3px 9px' }}
          onClick={() => {
            setDrawerOpen(true);
          }}
          disabled={rowSelectionModel.length !== 1}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <EditOutlinedIcon />
            <Typography variant='button' display='block' gutterBottom>
              Edit
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{ padding: '3px 9px' }}
          onClick={() => {
            deleteSelectedManifestChecks(rowSelectionModel);
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <DeleteOutlinedIcon />
            <Typography variant='button' display='block' gutterBottom>
              Delete
            </Typography>
          </Stack>
        </Button>
        <GridToolbarColumnsButton color='inherit' />
        <GridToolbarFilterButton color='inherit' />
        <GridToolbarDensitySelector color='inherit' />
        <GridToolbarExport color='inherit' />

        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }, [setDrawerOpen, deleteSelectedManifestChecks, rowSelectionModel]);

  if (loading_checks || loading_manifest) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          {manifest.name} Manifest
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() => {
              navigate({
                pathname: '/admin/manifests/edit',
                search: `?manifest_id=${manifest?.entity_id}`,
              });
            }}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />

      <Box padding='15px'>
        <Typography variant='subtitle'>Basic Information</Typography>
        <Divider />
        <br />
        <Grid
          container
          component='dl' // mount a Definition List
          spacing={0}
          sx={{ display: 'table' }}
        >
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>ID:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {manifest?.entity_id}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Created:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {toNiceTimestamp(manifest?.created_at, true)}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Reference Document:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {manifest?.reference_document?.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box padding='15px'>
        <Typography variant='subtitle'>Checks</Typography>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <DataGrid
                pageSizeOptions={[10, 20, 50]}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 20, page: 0 },
                  },
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      prompt: false,
                    },
                  },
                }}
                autoHeight
                checkboxSelection
                columnHeaderHeight={35}
                getRowId={(row) => row.entity_id}
                columns={columns}
                rows={manifest_checks || []}
                onSelectionModelChange={handleRowSelection}
                onRowSelectionModelChange={handleRowSelection}
                rowSelectionModel={rowSelectionModel}
                slots={{ toolbar: ManifestCheckGridToolbar }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <AdminManifestCheckDrawer
        key={`manifest-check-${selected?.entity_id}-${revision}`}
        open={drawerOpen}
        toggleDrawer={() => {
          setDrawerOpen(false);
          setRowSelectionModel([]);
        }}
        manifest_check={selected}
        manifest_id={manifest.entity_id}
        setManifestCheck={handleManifestCheckUpdate}
      />
      <ManifestDeleteDialog
        manifest_id={manifest.entity_id}
        deleteManifest={handleDeleteManifest}
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default AdminManifest;
