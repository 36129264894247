import React, { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Toolbar,
  Divider,
  Button,
  IconButton,
  Paper,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import {
  getInspection,
  updateInspection,
  createInspection,
} from '@/actions/inspection';
import InspectionNameField from './InspectionNameField';
import InspectionDocumentTypeField from './InspectionDocumentTypeField';
import InspectionManifestsField from './InspectionManifestsField';
import InspectionDocumentsField from './InspectionDocumentsField';

function InspectionForm({ type }) {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const configuration = useConfiguration();
  const [inspection, setInspection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState('');
  const [name_error, setNameError] = useState(false);
  const [document_type, setDocumentType] = useState();
  const [manifests, setManifests] = useState([]);
  const [documents, setDocuments] = useState([]);

  const sp = new URLSearchParams(location.search);
  const inspection_id = sp.get('inspection_id');

  const base_path =
    type === INSPECTION_TYPES.EFFECTIVE
      ? '/quartermaster/internalAudit'
      : '/quartermaster/evaluator';

  useEffect(() => {
    if (user?.entity_id && !inspection) {
      if (inspection_id) {
        getInspection(configuration.api, inspection_id)
          .then((data) => {
            setInspection(data);
            setName(data?.name);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [
    user?.entity_id,
    inspection_id,
    inspection,
    setInspection,
    configuration.api,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      name,
      type,
      document_type_id: document_type.entity_id,
      manifests: manifests.map((manifest) => manifest.entity_id),
      documents: documents.map((document) => document.entity_id),
    };
    console.log(payload);
    let updated_inspection;
    if (inspection_id) {
      updated_inspection = await updateInspection(
        configuration.api,
        inspection.entity_id,
        payload
      );
    } else {
      updated_inspection = await createInspection(configuration.api, payload);
    }
    navigate(`${base_path}/${updated_inspection.entity_id}`);
  };

  if (!user?.entity_id || (!inspection && inspection_id)) {
    return <LoadingMask />;
  }

  return (
    <Grid container>
      <Grid xs={12} md={12} lg={12}>
        <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
          <Toolbar
            sx={{
              minHeight: '55px !important',
            }}
          >
            <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Typography variant='h6' sx={{ mr: 4 }}>
              {inspection ? 'Edit Inspection' : 'New Inspection'}
            </Typography>
          </Toolbar>
        </Paper>
      </Grid>
      <Divider />
      <Grid xs={12} md={12} lg={12}>
        <Box
          padding='15px'
          sx={{
            maxWidth: 600,
          }}
        >
          <Grid container spacing={2} style={{ padding: '15px' }}>
            <Grid lg={12} md={12} xs={12}>
              <InspectionNameField
                value={name}
                setValue={setName}
                error={name_error}
                setError={setNameError}
                setChanged={() => setPristine(false)}
              />
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <InspectionDocumentTypeField
                value={document_type}
                setValue={setDocumentType}
                setChanged={() => setPristine(false)}
                edit={Boolean(inspection)}
              />
            </Grid>
            {document_type ? (
              <Grid lg={12} md={12} xs={12}>
                <InspectionManifestsField
                  value={manifests}
                  setValue={setManifests}
                  document_type_id={document_type.entity_id}
                  setChanged={() => setPristine(false)}
                  edit={Boolean(inspection)}
                />
              </Grid>
            ) : undefined}
            {document_type && type === INSPECTION_TYPES.EFFECTIVE ? (
              <Grid lg={12} md={12} xs={12}>
                <InspectionDocumentsField
                  value={documents}
                  setValue={setDocuments}
                  document_type_id={document_type.entity_id}
                  setChanged={() => setPristine(false)}
                  edit={Boolean(inspection)}
                />
              </Grid>
            ) : undefined}
          </Grid>
        </Box>

        <Grid lg={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Toolbar>
            <Stack spacing={2} direction='row'>
              <LoadingButton
                variant='contained'
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  pristine ||
                  Boolean(name_error) ||
                  !Boolean(name) ||
                  !Boolean(document_type) ||
                  !manifests?.length
                }
              >
                Submit
              </LoadingButton>

              <Button
                variant='outlined'
                color='inherit'
                onClick={() => navigate(-1)}
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InspectionForm;
