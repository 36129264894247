import { MODEL_FAMILIES } from './ModelFamilies';

export const MODEL_CONFIGURATIONS = {
  [MODEL_FAMILIES.LLAMA_2]: {
    max_input_length: 3072,
    new_tokens_max: 1024,
    prompt_template_tokens: 105,
    chat_pair_tokens: 34,
    eos_token: ['</s>'],
  },
  [MODEL_FAMILIES.LLAMA_3]: {
    max_input_length: 6144,
    new_tokens_max: 2048,
    prompt_template_tokens: 105,
    chat_pair_tokens: 34,
    eos_token: ['<|eot_id|>', '<|end_of_text|>'],
  },
  [MODEL_FAMILIES.LLAMA_3_1]: {
    max_input_length: 9216,
    new_tokens_max: 3072,
    prompt_template_tokens: 330,
    chat_pair_tokens: 34,
    eos_token: ['<|eot_id|>', '<|end_of_text|>'],
  },
  [MODEL_FAMILIES.LLAMA_3_2]: {
    max_input_length: 73728,
    new_tokens_max: 24576,
    prompt_template_tokens: 330,
    chat_pair_tokens: 34,
    eos_token: ['<|eot_id|>', '<|end_of_text|>'],
  },
  [MODEL_FAMILIES.MIXTRAL]: {
    max_input_length: 24576,
    new_tokens_max: 8192,
    prompt_template_tokens: 105,
    chat_pair_tokens: 34,
    eos_token: ['</s>'],
  },
  [MODEL_FAMILIES.IDEFICS]: {
    max_input_length: 1536,
    new_tokens_max: 512,
    prompt_template_tokens: 5,
    chat_pair_tokens: 34,
    eos_token: ['</s>', '<end_of_utterance>'],
    prompt_template: (prompt, image_url) =>
      `User: ${prompt}\n![](${image_url})\nAssistant: `,
  },
  [MODEL_FAMILIES.IDEFICS2]: {
    max_input_length: 6144,
    new_tokens_max: 2048,
    prompt_template_tokens: 5,
    chat_pair_tokens: 34,
    eos_token: ['</s>', '<end_of_utterance>'],
    prompt_template: (prompt, image_url) =>
      `User: ${prompt}\n![](${image_url})\nAssistant: `,
  },
  [MODEL_FAMILIES.LLAVA_VICUNA]: {
    max_input_length: 3072,
    new_tokens_max: 1024,
    prompt_template_tokens: 5,
    chat_pair_tokens: 34,
    eos_token: ['</s>'],
    prompt_template: (prompt, image_url) =>
      `USER: ![](${image_url})\n${prompt}\n ASSISTANT: `,
  },
  [MODEL_FAMILIES.LLAVA_MISTRAL]: {
    max_input_length: 24576,
    new_tokens_max: 8192,
    prompt_template_tokens: 5,
    chat_pair_tokens: 34,
    eos_token: ['</s>'],
    prompt_template: (prompt, image_url) =>
      `[INST] ![](${image_url})\n${prompt}\n [/INST]`,
  },
};
