import React, { useCallback, useState, useMemo } from 'react';
import { Box, Popover, Typography } from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CHECK_RESULT_STATUSES from '../checkResultStatuses';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function RunningInspectionDocumentChecks({ inspection, checks }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');

  const renderCompliant = useCallback(({ value }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {value === true ? (
          <CheckCircleIcon color='success' />
        ) : value === false ? (
          <HighlightOffIcon color='error' />
        ) : (
          ''
        )}
      </Box>
    );
  }, []);

  const renderRationale = useCallback(({ value }) => {
    return (
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
        }}
      >
        {value}
      </span>
    );
  }, []);

  const renderSuggestion = useCallback((params) => {
    if (params.row.status !== CHECK_RESULT_STATUSES.PASSED) {
      return (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
          }}
        >
          {params.value}
        </span>
      );
    }
  }, []);

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    if (['rationale', 'suggestion'].includes(field)) {
      const id = event.currentTarget.parentElement.dataset.id;
      const row = checks.find((r) => r.entity_id === id);
      setValue(row[field]);
      setAnchorEl(event.currentTarget);
    } else {
      setValue(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const columns = useMemo(
    () => [
      {
        field: 'check_section',
        headerName: 'Section',
        flex: 0.5,
        type: 'string',
      },
      {
        field: 'check_name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        type: 'string',
      },
      {
        field: 'compliant',
        headerName: 'Compliant',
        flex: 0.25,
        type: 'boolean',
        renderCell: renderCompliant,
      },
      {
        field: 'rationale',
        headerName: 'Rationale',
        flex: 1,
        type: 'string',
        renderCell: renderRationale,
      },
      {
        field: 'suggestion',
        headerName: 'Suggestion',
        flex: 1,
        type: 'string',
        renderCell: renderSuggestion,
      },
    ],
    [renderRationale, renderSuggestion, renderCompliant]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DataGrid
            pageSizeOptions={[10, 20, 50]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 20, page: 0 },
              },
            }}
            columnHeaderHeight={35}
            getRowId={(row) => row.entity_id}
            columns={columns}
            rows={checks || []}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              cell: {
                onMouseEnter: handlePopoverOpen,
                onMouseLeave: handlePopoverClose,
              },
              toolbar: {
                sx: {
                  button: {
                    color: 'black',
                  },
                  svg: {
                    color: 'black',
                  },
                },
                showQuickFilter: true,
              },
            }}
          />
          <Popover
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>{value}</Typography>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
}

export default RunningInspectionDocumentChecks;
