import React, { useState, useEffect } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { useDocTypes } from '@/providers/DocTypesContext';

function InspectionDocumentTypeField({ value, setValue, setChanged, edit }) {
  const doc_types = useDocTypes();

  const [selected, setSelected] = useState(value);
  const [options, setOptions] = useState(null);

  const handleChange = (event, newValue) => {
    setChanged();
    setSelected(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (doc_types && !options) {
      const maxLevel = Math.max(...doc_types.map((doc_type) => doc_type.level));
      const filteredArray = doc_types.filter(
        (doc_type) => doc_type.level >= maxLevel - 1
      );
      setOptions(filteredArray);
      setSelected(value);
    }
  }, [doc_types, options, value, setOptions, setSelected]);

  return (
    <Box>
      <Autocomplete
        id='document-types-field'
        options={options || []}
        value={selected}
        sx={{ width: '100%' }}
        disabled={edit}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        disableClearable={true}
        renderInput={(params) => (
          <TextField {...params} required label='Document Type to Inspect' />
        )}
      />
    </Box>
  );
}

export default InspectionDocumentTypeField;
