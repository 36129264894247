import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import DocumentCheck from './DocumentCheck';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function DocumentControlChecks({
  manifest,
  reference_doc_content,
  doc_checks,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: isMobile ? 400 : 650 }} stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: isMobile ? '100px' : '250px' }}>
              Criteria
            </StyledTableCell>
            <StyledTableCell
              sx={{ width: isMobile ? '60px' : '100px' }}
              align='center'
            >
              Evaluation
            </StyledTableCell>
            <StyledTableCell align='center'>Rationale</StyledTableCell>
            <StyledTableCell align='center'>Suggestion</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {doc_checks.map((doc_check) => (
            <DocumentCheck
              global
              key={doc_check.entity_id}
              doc_check={doc_check}
              manifest={manifest}
              reference_doc_content={reference_doc_content}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default DocumentControlChecks;
