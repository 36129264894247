import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { read, utils } from 'xlsx';

import getFile from '@/util/getFile';
import mammoth from 'mammoth/mammoth.browser';
import TurndownService from '@joplin/turndown';
import * as turndownPluginGfm from '@joplin/turndown-plugin-gfm';

const turndownService = new TurndownService({
  linkStyle: 'referenced',
  linkReferenceStyle: 'collapsed',
});

const gfm = turndownPluginGfm.gfm;
turndownService.use(gfm);

const FORMATS = {
  DOCX: 'docx',
  TEXT: 'text',
  XLSX: 'xlsx',
  CSV: 'csv',
};

const DOCUMENT_TYPES = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FORMATS.DOCX,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    FORMATS.XLSX,
  'text/plain': FORMATS.TEXT,
  'application/json': FORMATS.TEXT,
  'text/csv': FORMATS.CSV,
  'text/markdown': FORMATS.TEXT,
};

function csvToMarkdown(csv) {
  // Function to parse CSV data into rows with respect to quotes and newlines
  function parseCsv(data) {
    const rows = [];
    let currentRow = [];
    let currentValue = '';
    let inQuotes = false;

    for (let i = 0; i < data.length; i++) {
      const char = data[i];
      const nextChar = data[i + 1];

      if (char === '"' && nextChar === '"') {
        // Handle escaped quotes by adding a single quote and skipping the next character
        currentValue += '"';
        i++;
      } else if (char === '"') {
        // Toggle the inQuotes state
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        // If not in quotes, this comma ends the current value
        currentRow.push(currentValue.trim());
        currentValue = '';
      } else if ((char === '\n' || char === '\r') && !inQuotes) {
        // If not in quotes, a newline character indicates the end of a row
        if (currentValue) {
          currentRow.push(currentValue.trim());
        }
        rows.push(currentRow);
        currentRow = [];
        currentValue = '';
        // Skip the next character if it's a \r\n pair
        if (char === '\r' && nextChar === '\n') i++;
      } else {
        // Add regular characters to the current value
        currentValue += char;
      }
    }

    // Add any remaining data as the last row
    if (currentValue || currentRow.length > 0) {
      currentRow.push(currentValue.trim());
      rows.push(currentRow);
    }

    return rows;
  }

  // Parse CSV into rows
  const rows = parseCsv(csv);
  if (rows.length === 0) return '';

  // Create Markdown table from parsed rows
  const headers = rows[0];
  const headerRow = `| ${headers.join(' | ')} |`;
  const separatorRow = `| ${headers.map(() => '---').join(' | ')} |`;
  const dataRows = rows.slice(1).map((row) => `| ${row.join(' | ')} |`);

  return [headerRow, separatorRow, ...dataRows].join('\n');
}

function DocumentUploader({ max_docs, setDocuments }) {
  const [doc_content, setDocContent] = useState('');
  const [files_uploaded, setFilesUploaded] = useState([]);

  const handleUpload = useCallback(
    async (files) => {
      if (files[0]) {
        const document = await getFile(files[0]);

        if (document) {
          const processed = { name: document.name };
          switch (DOCUMENT_TYPES[document.type]) {
            case FORMATS.DOCX:
              {
                const html = await mammoth.convertToHtml(
                  {
                    arrayBuffer: document.contents,
                  },
                  {
                    convertImage: mammoth.images.imgElement(function (image) {
                      return '';
                    }),
                  }
                );
                const markdown = turndownService.turndown(html.value);
                processed.contents = markdown;
              }
              break;
            case FORMATS.TEXT:
            case FORMATS.CSV:
              processed.contents = document.contents;
              break;
            case FORMATS.XLSX:
              {
                const workbook = read(document.contents);
                const sheets = workbook.SheetNames.map((sheet_name) => {
                  return csvToMarkdown(
                    utils.sheet_to_csv(workbook.Sheets[sheet_name], {
                      blankrows: false,
                      forceQuotes: true,
                    })
                  );
                });

                processed.contents = sheets.join('\n\n');
                console.log(processed.contents);
              }
              break;
            default:
              throw new Error('Unsupported file format');
          }

          setDocContent(processed.contents);
          setDocuments([processed]);
          setFilesUploaded([]);
        }
      }
    },
    [setDocuments, setDocContent, setFilesUploaded]
  );

  return (
    <Box>
      <DropzoneArea
        maxFileSize={15000000}
        filesLimit={max_docs}
        dropzoneText={
          Boolean(doc_content)
            ? 'Upload again to replace document'
            : 'Drag and drop a file here or click'
        }
        acceptedFiles={Object.keys(DOCUMENT_TYPES)}
        fileObjects={files_uploaded}
        onChange={handleUpload}
        showPreviewsInDropzone={false}
      />
    </Box>
  );
}

export default DocumentUploader;
