import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Popover, Typography } from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import INSPECTION_STATUSES from '../../../../inspectionStatuses';
import { getInspectionChecks } from '../../../../../actions/inspectionCheck';
// import { Link as RouterLink } from 'react-router-dom';

// import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import CHECK_RESULT_STATUSES from '../checkResultStatuses';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
// import { getManifests } from '@/actions/manifest';

function InspectionDocumentManifestChecks({ inspection, document, manifest }) {
  // const user = useUser();
  const configuration = useConfiguration();
  const [checks, setChecks] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (inspection?.status !== INSPECTION_STATUSES.DRAFT && !checks) {
      getInspectionChecks(
        configuration.api,
        inspection.entity_id,
        document.document_entity_id
      )
        .then((data) => {
          data.sort((a, b) => {
            if (a.check_section === b.check_section) {
              return (
                (a.check_name > b.check_name) - (a.check_name < b.check_name)
              );
            } else {
              return (
                (a.check_section > b.check_section) -
                (a.check_section < b.check_section)
              );
            }
          });
          setChecks(data);
        })
        .catch((error) => console.log(error));
    }
  }, [
    inspection?.status,
    inspection.entity_id,
    checks,
    setChecks,
    configuration.api,
    document.document_entity_id,
  ]);

  // const convertDate = useCallback(({ value }) => {
  //   if (!value) {
  //     return value;
  //   }
  //   return new Date(value.replace(' ', 'T') + 'Z');
  // }, []);

  const draft_columns = useMemo(
    () => [
      {
        field: 'section',
        headerName: 'Section',
        flex: 0.5,
        type: 'string',
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
      },

      // {
      //   field: 'created_at',
      //   headerName: 'Started',
      //   type: 'dateTime',
      //   flex: 0.75,
      //   valueGetter: convertDate,
      //   // renderCell: renderName,
      // },
      // {
      //   field: 'updated_at',
      //   headerName: 'Last Updated',
      //   type: 'dateTime',
      //   flex: 0.75,
      //   valueGetter: convertDate,
      //   // renderCell: renderName,
      // },
    ],
    []
  );

  const renderCompliant = useCallback(({ value }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {value === true ? (
          <CheckCircleIcon color='success' />
        ) : value === false ? (
          <HighlightOffIcon color='error' />
        ) : (
          <WarningIcon color='warning' />
        )}
      </Box>
    );
  }, []);

  const renderRationale = useCallback(({ value }) => {
    return (
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
        }}
      >
        {value}
      </span>
    );
  }, []);

  const renderSuggestion = useCallback((params) => {
    if (params.row.status !== CHECK_RESULT_STATUSES.PASSED) {
      return (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
          }}
        >
          {params.value}
        </span>
      );
    }
  }, []);

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    if (['rationale', 'suggestion'].includes(field)) {
      const id = event.currentTarget.parentElement.dataset.id;
      const row = checks.find((r) => r.entity_id === id);
      setValue(row[field]);
      setAnchorEl(event.currentTarget);
    } else {
      setValue(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const completed_columns = useMemo(
    () => [
      {
        field: 'check_section',
        headerName: 'Section',
        flex: 0.5,
        type: 'string',
      },
      {
        field: 'check_name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
      },
      {
        field: 'compliant',
        headerName: 'Compliant',
        flex: 0.25,
        type: 'boolean',
        renderCell: renderCompliant,
      },
      {
        field: 'rationale',
        headerName: 'Rationale',
        flex: 1,
        type: 'string',
        renderCell: renderRationale,
      },
      {
        field: 'suggestion',
        headerName: 'Suggestion',
        flex: 1,
        type: 'string',
        renderCell: renderSuggestion,
      },
    ],
    [renderCompliant, renderRationale, renderSuggestion]
  );

  if (inspection.status !== INSPECTION_STATUSES.DRAFT && !checks) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DataGrid
            pageSizeOptions={[10, 20, 50]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 20, page: 0 },
              },
            }}
            columnHeaderHeight={35}
            getRowId={(row) => row.entity_id}
            columns={
              inspection?.status === INSPECTION_STATUSES.DRAFT
                ? draft_columns
                : completed_columns
            }
            rows={
              (inspection?.status === INSPECTION_STATUSES.DRAFT
                ? manifest?.checks
                : checks) || []
            }
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              cell: {
                onMouseEnter: handlePopoverOpen,
                onMouseLeave: handlePopoverClose,
              },
              toolbar: {
                sx: {
                  button: {
                    color: 'black',
                  },
                  svg: {
                    color: 'black',
                  },
                },
                showQuickFilter: true,
              },
            }}
          />
          <Popover
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>{value}</Typography>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
}

export default InspectionDocumentManifestChecks;
