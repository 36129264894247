import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Typography,
  Stack,
  TableRow,
  TableCell,
  Link,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DocumentCheckResultDialog from './DocumentCheckResultDialog';
import WarningIcon from '@mui/icons-material/Warning';
import WorkInstructionViewDialog from './WorkInstructionViewDialog';
import CHECK_RESULT_STATUSES from '../Inspections/Inspection/checkResultStatuses';

const response_re =
  /^(?<status>FAILED|PASSED)\s*\*Analysis\*[:\s]*(?<rationale>[\s\S]*)\s*\*Suggestions for Improvements{0,1}\*[:\s]*(?<suggestion>[\s\S]*)$/;

const PASSED = /^PASSED/;
const FAILED = /^FAILED/;

const calculateStatus = (status) => {
  if (status.match(PASSED)) {
    return CHECK_RESULT_STATUSES.PASSED;
  }
  if (status.match(FAILED)) {
    return CHECK_RESULT_STATUSES.FAILED;
  }
  return CHECK_RESULT_STATUSES.UNKNOWN;
};

function DocumentCheck({ global, manifest, doc_check, reference_doc_content }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [rationale, setRationale] = useState(null);
  const [suggestion, setSuggestion] = useState(null);
  const [openWorkInstruction, setOpenWorkInstruction] = useState(false);

  useEffect(() => {
    if (doc_check.result) {
      const match = doc_check.result.match(response_re);
      if (match) {
        setStatus(calculateStatus(match.groups.status));
        setRationale(match.groups.rationale);
        setSuggestion(match.groups.suggestion);
      } else {
        setStatus(CHECK_RESULT_STATUSES.UNKNOWN);
        setRationale(doc_check.result);
      }
    }
  }, [doc_check?.result, setStatus, setRationale, setSuggestion]);

  const renderStatus = (status) => {
    switch (status) {
      case CHECK_RESULT_STATUSES.PASSED:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <CheckCircleIcon color='success' />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
      case CHECK_RESULT_STATUSES.UNKNOWN:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <WarningIcon color='warning' />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
      case CHECK_RESULT_STATUSES.FAILED:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <HighlightOffIcon color='error' />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
      default:
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <CircularProgress size={20} />
            <Typography variant='body2'>{status}</Typography>
          </Stack>
        );
    }
  };

  return (
    <TableRow>
      {!global ? (
        <TableCell>
          <Typography variant='body'>{doc_check.section}</Typography>
        </TableCell>
      ) : undefined}
      <TableCell>
        {doc_check.global ? (
          <Typography variant='body'>{doc_check.name}</Typography>
        ) : (
          <Link
            sx={{ textAlign: 'left' }}
            component='button'
            variant='body2'
            onClick={() => {
              setOpenWorkInstruction(true);
            }}
          >
            <Typography variant='body'>{doc_check.name}</Typography>
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant='body'>
          {renderStatus(status || doc_check.status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body' sx={{ whiteSpace: 'pre-wrap' }}>
          {rationale}
        </Typography>
      </TableCell>
      <TableCell>
        {status === CHECK_RESULT_STATUSES.FAILED ? (
          <Typography variant='body' sx={{ whiteSpace: 'pre-wrap' }}>
            {suggestion}
          </Typography>
        ) : undefined}
      </TableCell>
      <DocumentCheckResultDialog
        doc_check={doc_check}
        result={doc_check.result}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <WorkInstructionViewDialog
        open={openWorkInstruction}
        handleClose={() => setOpenWorkInstruction(false)}
        content={reference_doc_content}
        title={`${manifest.name} Requirements`}
      />
    </TableRow>
  );
}
export default DocumentCheck;
